import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import TALogo from "../assets/talogo.svg";
import { IoIosSearch } from "react-icons/io" 
import styles from "../styles/header.module.css"
import {isMobile} from 'react-device-detect';

class Header extends Component{
  constructor(props){
    super(props)
    this.state = {
      minified: false
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    if(window.scrollY >= 100 && !this.state.minified){
      this.setState({
        minified: true
      })
    }

    if(window.scrollY < 100 && this.state.minified){
      this.setState({
        minified: false
      })
    }
  }

  render = () => {
    return (
      <header className={styles.headerContainer} style={this.state.minified? {boxShadow: ' 0 2px 2px -2px rgba(0,0,0,.15)'}: {}}>
        <div className={styles.headerContentContainer}>
          <div className={styles.headerTextContainer}>
            <TALogo className={styles.headerIcon} style={this.state.minified? {width: '2.5rem', height: '2.5rem'}: {width: '4rem', height: '4rem'}}/>
            <h1 className={styles.headerText} style={{fontSize: this.state.minified? '1.7rem' :'2.25rem'}}>
              <Link to="/">
                {"Tasty Appetite"}
              </Link>
            </h1>
          </div>
          <Link to="/search">
            <div className={styles.searchContainer}>
              {isMobile?'': <span className={styles.searchLabel}>Search</span>}
              <IoIosSearch className={styles.searchIcon} size={this.state.minified? '1.5em': '2em'} color='gray'/>
            </div>
          </Link>
        </div>
    </header>
    )
  }
}


export default Header
