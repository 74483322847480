import rubik from "typeface-rubik"
import dancingScript from "typeface-dancing-script"
import lunr from "lunr"
import "./src/styles/global.module.css"


const transitionDelay = 200

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      require('intersection-observer')
    }
}

export const onRouteUpdate = ({location}) => {
    if (location.pathname.indexOf('/search') !== -1  && (!window.__LUNR__)){
        fetch(`${__PATH_PREFIX__}/search_index.json`)
        .then(function(response) {
        return response.json()
        })
        .then(function(fullIndex) {
        window.__LUNR__ = Object.keys(fullIndex).reduce(
            (prev, key) => ({
            ...prev,
            [key]: {
                index: lunr.Index.load(fullIndex[key].index),
                store: fullIndex[key].store,
            },
            }),
            {}
        )
        })
        .catch(e => console.log('Failed fetch search index'))
    }
}