export const getPath = (url, created_date, title, extension) => {
    if(!extension){
        extension = ''
    }
    if(url && url.length > 0){
        return url.split("https://www.tastyappetite.net")[1]
    } else {
        const createdDate = new Date(created_date)
        const year = createdDate.getFullYear()
        let month = createdDate.getMonth()
        month = String(month).length === 1? '0' + String(month): month
        const titleSlug = title.replace(/[!./:,|]/g, ' ').replace(/ /g, '-').toLowerCase() + extension
        return '/' + String(year) + '/' + String(month) + '/' + titleSlug + '.html' 
    }
}