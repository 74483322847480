/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Menu from "../components/menu"
import {isMobile} from 'react-device-detect';
import Transition from "../components/transition"
import styles from "../styles/layout.module.css"
import Helmet from "react-helmet"
import Footer from "../components/footer"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          adSenseId
        }
      }
    }
  `)

  const MenuDisplay = <Menu/>

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className={styles.container}>
        <Helmet>
          <script data-ad-client={data.site.siteMetadata.adSenseId} async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"/>
        </Helmet>
        
        {MenuDisplay}
        <div className={styles.bodyContainer}>
          <main>
            <Transition location={location}>
              {children}
            </Transition>
          </main>
          <Footer/>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
