// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-category-collection-js": () => import("./../../../src/templates/categoryCollection.js" /* webpackChunkName: "component---src-templates-category-collection-js" */),
  "component---src-templates-cuisine-collection-js": () => import("./../../../src/templates/cuisineCollection.js" /* webpackChunkName: "component---src-templates-cuisine-collection-js" */),
  "component---src-templates-keyword-collection-js": () => import("./../../../src/templates/keywordCollection.js" /* webpackChunkName: "component---src-templates-keyword-collection-js" */),
  "component---src-templates-post-amp-js": () => import("./../../../src/templates/post.amp.js" /* webpackChunkName: "component---src-templates-post-amp-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/postsPage.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-recipe-collection-js": () => import("./../../../src/templates/recipeCollection.js" /* webpackChunkName: "component---src-templates-recipe-collection-js" */)
}

