import React from 'react'
//import FbLikeButton from './fbLikeButton'
import { useEffect } from 'react';
import styles from '../styles/footer.module.css'
import InstaLogo from "../assets/insta.svg";
import {AiFillFacebook, AiOutlineInstagram, AiFillYoutube, AiOutlineTwitter} from 'react-icons/ai'
import {FaPinterest} from 'react-icons/fa'



const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

const Footer = () => {
    useScript('https://apis.google.com/js/platform.js')
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.socialIcons}>
                <div className={styles.followIcons}>
                    <p>Follow <span className={styles.headerText}>Tasty Appetite</span> on</p>
                    <a className={styles.iconLink} target="_blank" href='https://www.facebook.com/TastyAppetite/'><AiFillFacebook color='#4267B2'  size='3em'/></a>
                    <a className={styles.iconLink} target="_blank" href='https://www.instagram.com/tastyappetite/'>
                        <InstaLogo style={{width: '2.8em', height:'2.8em'}}/>
                    </a>
                    <a className={styles.iconLink} target="_blank" href='https://www.youtube.com/channel/UCcj1hpspM1khZzEPS3YqYaQ'><AiFillYoutube color='red'  size='3em'/></a>
                    <a className={styles.iconLink} target="_blank" href='https://twitter.com/tastyappetite?lang=en'><AiOutlineTwitter color='#1DA1F2'  size='3em'/></a>
                    <a className={styles.iconLink} target="_blank" href='https://in.pinterest.com/jaypremz/'><FaPinterest color='#E60023' size='3em'/></a>
                </div>
                {/* <div className={styles.footerRowIcon}>
                    <FbLikeButton />    
                </div>
                <div className={styles.footerRowIcon}>
                    <div className="g-ytsubscribe" data-channelid="UCcj1hpspM1khZzEPS3YqYaQ" data-layout="default" data-count="default"></div>
                </div> */}
                <div className={styles.footerRow}>
                    <p> © Tasty Appetite </p>   
                </div>
            </div>
            
        </div> 
    )
}
//© Tasty Appetite

export default Footer