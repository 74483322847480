module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"red","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tasty Appetite","short_name":"Tasty Appetite","start_url":"/","background_color":"#ffffff","theme_color":"#ff0000","display":"standalone","icon":"src/assets/talogo.svg","scope":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3029b4e24130690c07ae6e2daae0b419"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-161818136-1","head":false,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"siteSpeedSampleRate":10},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest-twitter-facebook/gatsby-browser.js'),
      options: {"plugins":[],"delayTimer":1000,"pinterest":{"enable":true,"tall":false,"round":false},"twitter":{"enable":false,"containerSelector":".twitterContainer","handle":"TastyAppetite","showFollowButton":true,"showTimeline":false,"showFollowerCount":true,"timelineTweetCount":1,"width":null,"height":null,"noHeader":true,"noFooter":true,"noBorders":true,"noScrollbar":true,"transparent":true},"facebook":{"enable":false,"containerSelector":".facebookContainer","profile":"TastyAppetite","width":null,"height":null,"tabs":"","hideCover":true,"showFacepile":false,"smallHeader":true,"adaptContainerWidth":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
