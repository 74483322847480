import React from "react"
import styles from "../styles/menu.module.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import { node } from "prop-types"
import { getPath } from "./utils"
import HorizontalSlider from "./horizontalSlider"

const Menu = () => {
    const data = useStaticQuery(graphql`
    {
        allStrapiMenuItems {
            edges {
              node {
                TItle
                post {
                  id
                  title
                  created_at
                }
                collection_of_recipes {
                  title
                  websiteURL
                  created_at
                }
                category {
                  name
                }
                cuisine {
                  name
                }
                keyword {
                  name
                }
                priority
              }
            }
          }
      }
    `)
    let menuItems = data.allStrapiMenuItems.edges.map((doc) => {
        const node = doc.node
        let item = {
            'title': node.TItle,
            'priority': node.priority
        }
        if(node.post){
            item['url'] = getPath(node.post.websiteURL, node.post.created_at, node.post.title)
        }
        else if(node.collection_of_recipes){
            item['url'] = getPath(node.collection_of_recipes.websiteURL, node.collection_of_recipes.created_at, node.collection_of_recipes.title, '-collection')
        }
        else if(node.keyword){
            item['url'] = '/collection/' + node.keyword['name'].replace(/ /g, '_').toLowerCase() + '/'
        }
        else if(node.cuisine){
            item['url'] = '/cuisine/' + node.cuisine['name'].replace(/ /g, '_').toLowerCase() + '/'
        }
        else if(node.category){
            item['url'] = '/category/' + node.category['name'].replace(/ /g, '_').toLowerCase() + '/'
        }
        return item
    })

    menuItems = menuItems.sort((i, j) => i['priority']>j['priority']? 1 : -1)
    menuItems.unshift({'title': 'Home', 'priority': -1000, 'url': '/'})

    return (
            <div className={styles.menuContainer}>
                <HorizontalSlider>
                    {menuItems.map((item, i) => {
                        if(item['url']){
                            return <Link to={item['url']}>
                                <div key={i} className={styles.menuItem}>{item['title']}</div>
                            </Link>
                        } else {
                            return ''
                        }
                        
                    })}
                </HorizontalSlider>
            </div>
    )
}

export default Menu